import Logo from '../logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

function Landing({titleRef,arrowOpacity}){
    return(
    <section id="landing">
        <div ref={titleRef} id="title">
          <img src={Logo} alt="Cousin Bizarre" />
          <h5>Creative Visual Effects Studio</h5>
        </div>
        <FontAwesomeIcon icon={faChevronDown}  size="2x" className={`downarrow ${arrowOpacity}`} style={{"marginTop":"15vh"}}/>
    </section>
    )

}
export default Landing;
