import {firestore,storage} from '../services/firestore';
import { collection, query, where, getDocs } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect,useState } from 'react';

// Fetch every projects data
export function useProjectsData(){

    const [projects, setProjects] = useState([])

    useEffect(() => {

        const getData = async () => {
          const q = query(collection(firestore,"projects"), where('published','==',true))
          const querySnapshot = await getDocs(q);

          let databaseInfo = []

          querySnapshot.forEach((snap)=>{
            databaseInfo.push({...snap.data(), id: snap.id})
          })


          
          //await Promise.all(databaseInfo.map((data,index)=> getDownloadURL(ref(storage,data.video_url)).then(url=>databaseInfo[index].video_url = url)))
          await Promise.all(databaseInfo.map((data,index)=> data.image_url?getDownloadURL(ref(storage,data.image_url)).then(url=>databaseInfo[index].image_url = url):data.image_url))
          
          databaseInfo = databaseInfo.sort((a,b)=>(b.date.toDate() - a.date.toDate()))
          setProjects(databaseInfo)
        }
        getData()
      }, [])

      return projects
}