
import data from './data.json';
import Landing from './components/Landing'
import Project from './components/Project'
import TalentPanel from './components/TalentPanel'

import Logo from './logo.svg'

import { useEffect, useMemo, useRef, useState,useCallback } from 'react';
import useOnScreen from './hooks/useOnScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faInstagram,faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { useProjectsData } from './hooks/firebasehooks';

function App() {
  const [detectScroll,setScrollDetect]=useState(false)

  /*const allProjects = useMemo(()=>{
    let projects = data.projects.slice();
    data.talents.forEach((talent)=>{projects = projects.concat(talent.projects)});
    return projects;
  },[])*/

  const allProjects = useProjectsData()

  const sections = useMemo(()=>{
    return [
      {
        name:"Projets",
        id:"projects",
        url:"",
        projects:allProjects.filter((val)=>val.category==='project')
      },
      {
        name:"Grégoire Delzongle",
        id:"talentg",
        url:"https://www.imdb.com/name/nm7732095/",
        projects:allProjects.filter((val)=>val.category==='talentg')
      },
      {
        name:"Félix Pirritano",
        id:"talentf",
        url:"https://www.imdb.com/name/nm8137869/",
        projects:allProjects.filter((val)=>val.category==='talentf')
      },
      {
        name:"Thibaut Jouhannet",
        id:"talentt",
        url:"https://www.imdb.com/name/nm6398248/",
        projects:allProjects.filter((val)=>val.category==='talentt')
      },
      {
        name:"Hugo Jean",
        id:"talenth",
        url:"https://www.imdb.com/name/nm7919828/",
        projects:allProjects.filter((val)=>val.category==='talenth')
      }
    ] 
  },[allProjects])

  const titleRef = useRef()
  const isVisible = useOnScreen(titleRef)

  const [arrowOpacity,setArrowOpacity] = useState(true);

  const onScroll = useCallback(e => {
      if(detectScroll){
        setSelection(undefined);
        setScrollDetect(false);
      }

      let currentScrollPos = window.pageYOffset;
      let maxScroll = document.body.scrollHeight - window.innerHeight;
      setArrowOpacity(currentScrollPos > 0 && currentScrollPos < maxScroll?"hide":"")
  },[detectScroll,setArrowOpacity]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });
  
  const [selection,setSelection] = useState(undefined)

  return (
    <div className="App">
      <header className={isVisible?"":"visible"}>
        <div id="site-title">
          <a href="#landing" data-scroll="" onPointerDown={()=>setSelection(undefined)}><img src={Logo} alt="Cousin Bizarre"/></a>
        </div>
        <div id="contact-link">
          <a href="#section-contact" data-scroll="" onPointerDown={()=>setSelection(undefined)}><h4>Contact</h4></a>
        </div>  
      </header>
      <Landing titleRef={titleRef} arrowOpacity={arrowOpacity}/>
      <section id="projects" className="separator">
        <h1 className={`section-title separator animate-opacity ${selection!==undefined?"hide":""}`}>Work</h1>
        {sections.filter((s)=>s.id==='projects')[0].projects.map((project)=>(
            <Project 
                project={project}
                key={project.id}
                setScrollDetect={setScrollDetect}
                selectionName={selection}
                selected={selection===project.id}
                selectionIndex={allProjects.findIndex((value)=>value.id===selection)}
                projectIndex={allProjects.findIndex((value)=>value.id===project.id)}
                toggleInfos
                onSelected={(id)=>{
                  setSelection(id)
                }}
                />
            ))}
      </section>
      
      {/* <section id="talents" className={`separator animate-opacity`}>
        <h1 className={`section-title separator ${selection!==undefined?"hide":""}`}>Talents</h1>
        <div className="talents-contenair">
        {sections.filter((s)=>s.id!=='projects').map((talent)=>(
          <TalentPanel 
              key={talent.id} 
              talent={talent}
              selectionName={selection}
              selectionIndex={allProjects.findIndex((value)=>value.id===selection)}
              allProjects={allProjects}
              onSelected={(id)=>{
                setSelection(id)
              }}
              setScrollDetect={setScrollDetect}
              />
        ))}
        </div>
      </section> */}
      
      <section id="section-contact">
        <h1 className={`section-title animate-opacity ${selection!==undefined?"hide":""}`} style={{paddingBottom:"5vh"}}>Contact</h1>
        <div className="inner">
          <h4>Creative Visual Effects Studio based in Paris, France</h4>
          <ul className="inline-list">
            <li><a href="https://fr.linkedin.com/company/cousinbizarre" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /> Linkedin</a></li>
            <li><a href="https://www.instagram.com/cousin.bizarre/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/> Instagram</a></li>
            <li><a href="mailto:hello@cousinbizarre.com" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faEnvelope} /> Contact us</a></li>
          </ul>
        </div>
        <div className="space"></div>
        <p className="bottom">© 2023, All rights Reserved</p>
      </section>
    </div>)
}

export default App;