import { useState,useRef, useMemo, useEffect } from "react"
import ReactMarkdown from 'react-markdown'

function Project({project,onSelected,setScrollDetect,selected,selectionName,selectionIndex,projectIndex,titleStyle,...props}){

    const [hovered,setHovered] = useState(false)

    const ref = useRef();

    const date = useMemo(()=>project.date.toDate(),[project])

    const scrollToProject = ()=>{        
        ref.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        })};

    return (
    <div className={`project ${selected?"border":""}`} >
        {
            'mp4_video_url' in project && (hovered || selected) && (
            <div className="video-container">
                <video muted autoPlay loop poster={project.image_url} playsInline className="background-video fadein">
                    <source src={project.mp4_video_url} type="video/mp4"/>
                </video>
            </div>
            )
        }
        <button id={project.id} className={`project-contenair ${selected?"locked":""} ${selectionIndex===-1 || projectIndex===selectionIndex?"":projectIndex>selectionIndex?"down":"up"}`}
                onPointerEnter={()=>{
                        setHovered(true);
                    }} 
                onPointerLeave={()=>setHovered(false)}
                onPointerUp={()=>{

                    if(!('toggleInfos' in props) && 'url' in project){
                        window.open(project.url);
                        return;
                    }

                    onSelected(selected===true?undefined:project.id)
                    if(!selected){
                        scrollToProject();
                        setTimeout(function(){setScrollDetect(true)  }, 1000);
                    }else{
                        setScrollDetect(false);
                    }
                }}
                ref={ref}
                >
            <h2  className={"project-title"} style={titleStyle}>{project.name}</h2>
            {selected && 'toggleInfos' in props && <div className="project-more">
                <div className="project-infos">
                    <span className="bold">Year :   </span><span className="project-year">{`${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`}</span>
                    <br></br>
                    <span className="bold">Client : </span><span className="project-client">{project.client}</span>
                </div>
                <ReactMarkdown className="project-description" children={project.description}/>
            
            </div>
            }
            
        </button>
    </div>
    )
}
export default Project